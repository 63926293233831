import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import {
  createCategory,
  createSubCategory,
  deleteCategory,
  deleteSubCategory,
  getAllCategories,
  getCategoryById,
  getSubCategoryById,
  updateCategory,
  updateSubCategory,
} from '@modules/Finance/services/categories';
import {
  SubCategoryActionTypeEnum,
  TransactionTypeEnum,
} from '@modules/Finance/ultis/categories/enums';
import useDataFetch from '@modules/Finance/hooks/useDataFetch';
import { ICategory, ICreateCategory, ISubCategory } from '@modules/Finance/types/categories';

import { CategoriesContextType, CategoriesActionsProviderProps } from './types';

export const CategoriesContext = createContext<CategoriesContextType | undefined>(undefined);

export default function CategoriesProvider({ children }: CategoriesActionsProviderProps) {
  // * [Datas] * //
  const {
    data: categories,
    setData: setCategories,
    loading: loadingCategories,
    setLoading: setLoadingCategories,
  } = useDataFetch<ICategory[] | null>(null);

  const {
    data: category,
    setData: setCategory,
    loading: loadingCategory,
    setLoading: setLoadingCategory,
  } = useDataFetch<ICategory | null>(null);

  const { loading: loadingCreateCategory, setLoading: setLoadingCreateCategory } =
    useDataFetch(null);

  const {
    data: updatedCategory,
    setData: setUpdatedCategory,
    loading: loadingUpdateCategory,
  } = useDataFetch<ICategory | null>(null);

  // * [SubCategories] * //
  const { loading: loadingCreateSubCategory, setLoading: setLoadingSubCreateCategory } =
    useDataFetch(null);

  const {
    data: subCategory,
    setData: setSubCategory,
    loading: loadingSubCategory,
    setLoading: setLoadingSubCategory,
  } = useDataFetch<ISubCategory | null>(null);

  const {
    data: updatedSubCategory,
    setData: setUpdatedSubCategory,
    loading: loadingUpdateSubCategory,
    setLoading: setLoadingUpdateSubCategory,
  } = useDataFetch<ISubCategory | null>(null);

  // * [States] * //
  const [categoryActionType, setCategoryActionType] = useState<TransactionTypeEnum>(
    TransactionTypeEnum.Expense,
  );
  const [subCategoryActionType, setSubCategoryActionType] =
    useState<SubCategoryActionTypeEnum | null>(null);

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState<string | null>(null);

  const [showManageCategoryModal, setShowManageCategoryModal] = useState<boolean>(false);
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState<boolean>(false);

  const [loadingDeleteCategory, setLoadingDeleteCategory] = useState<boolean>(false);
  const [loadingDeleteSubCategory, setLoadingDeleteSubCategory] = useState<boolean>(false);

  // * [Functions] * //
  const validateFieldsForCategory = (
    fields: Record<string, any>,
    messages: Record<string, string>,
  ) => {
    for (const key in fields) {
      if (!fields[key]) {
        throw new Error(messages[key]);
      }
    }
  };

  const handleToggleManageCategoryModal = () => {
    setShowManageCategoryModal((prev) => !prev);
    setCategory(null);
  };

  const handleGetAllCategories = async () => {
    setLoadingCategories(true);

    try {
      const response = await getAllCategories();

      const filteredData = response?.categories.filter(
        (category) => category.type === categoryActionType,
      );

      setCategories(filteredData);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar categorias');
    } finally {
      setLoadingCategories(false);
    }
  };

  const handleGetCategoryById = async (id: string) => {
    setLoadingCategory(true);

    try {
      const response = await getCategoryById(id);

      setCategory(response);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar categoria');
    } finally {
      setLoadingCategory(false);
    }
  };

  const handleCreateCategory = async (data: ICreateCategory) => {
    setLoadingCreateCategory(true);
    const { name, color, icon, type } = data;

    try {
      validateFieldsForCategory(
        { name, color, icon },
        {
          name: 'Nome da categoria é obrigatório',
          color: 'Cor da categoria é obrigatória',
          icon: 'Ícone da categoria é obrigatório',
        },
      );

      const response = await createCategory({
        name,
        color,
        icon,
        type,
      });

      if (response) {
        handleToggleManageCategoryModal();
        handleGetAllCategories();
        toast.success('Categoria criada com sucesso');
      }
    } catch (error: any) {
      toast.error(error.message || 'Erro ao criar categoria');
    } finally {
      setLoadingCreateCategory(false);
    }
  };

  const handleUpdateCategory = async (id: string, updatedCategory: ICreateCategory) => {
    const { name, color, icon } = updatedCategory;
    try {
      validateFieldsForCategory(
        { name, color, icon },
        {
          name: 'Nome da categoria é obrigatório',
          color: 'Cor da categoria é obrigatória',
          icon: 'Ícone da categoria é obrigatório',
        },
      );

      const response = await updateCategory(id, updatedCategory);

      if (response) {
        handleToggleManageCategoryModal();
        handleGetAllCategories();
        toast.success('Categoria atualizada com sucesso');
      }

      setUpdatedCategory(response);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao atualizar categoria');
    }
  };

  const handleDeleteCategory = async (id: string, destinyCategoryID: string) => {
    setLoadingDeleteCategory(true);

    try {
      await deleteCategory(id, destinyCategoryID);

      toast.success('Categoria excluída com sucesso');
    } catch (error: any) {
      toast.error(error.message || 'Erro ao excluir categoria');
    } finally {
      setLoadingDeleteCategory(false);
      setShowDeleteCategoryModal(false);
      handleGetAllCategories();
    }
  };

  // * [SubCategories] * //
  const handleCreateSubCategory = async (categoryId: string, name: string) => {
    setLoadingSubCreateCategory(true);

    try {
      const response = await createSubCategory(categoryId, name);

      if (response) {
        handleGetAllCategories();
        toast.success('Subcategoria criada com sucesso');
      }
    } catch (error: any) {
      toast.error(error.message || 'Erro ao criar subcategoria');
    } finally {
      setLoadingSubCreateCategory(false);
    }
  };

  const handleGetSubCategoryById = async (id: string) => {
    setLoadingSubCategory(true);

    try {
      const response = await getSubCategoryById(id);

      setSubCategory(response);
    } catch (error: any) {
      toast.error(error.message || 'Erro ao buscar subcategoria');
    } finally {
      setLoadingSubCategory(false);
    }
  };

  const handleUpdateSubCategory = async (
    subCategoryId: string,
    categoryId: string,
    name: string,
  ) => {
    setLoadingUpdateSubCategory(true);

    try {
      const response = await updateSubCategory(subCategoryId, categoryId, name);

      if (response) {
        handleGetAllCategories();
        toast.success('Subcategoria atualizada com sucesso');
      }

      setUpdatedSubCategory(response);
      getAllCategories();
    } catch (error: any) {
      toast.error(error.message || 'Erro ao atualizar subcategoria');
    } finally {
      setLoadingUpdateSubCategory(false);
    }
  };

  const handleDeleteSubCategory = async (id: string) => {
    setLoadingDeleteSubCategory(true);

    try {
      await deleteSubCategory(id);

      toast.success('Subcategoria excluída com sucesso');
      handleGetAllCategories();
    } catch (error: any) {
      toast.error(error.message || 'Erro ao excluir subcategoria');
    } finally {
      setLoadingDeleteSubCategory(false);
    }
  };

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        category,
        subCategory,
        updatedCategory,
        updatedSubCategory,
        categoryActionType,
        subCategoryActionType,
        selectedCategoryId,
        selectedSubCategoryId,
        showManageCategoryModal,
        showDeleteCategoryModal,
        //
        loadingCategories,
        loadingCategory,
        loadingSubCategory,
        loadingCreateCategory,
        loadingUpdateCategory,
        loadingUpdateSubCategory,
        loadingDeleteCategory,
        loadingCreateSubCategory,
        loadingDeleteSubCategory,
        //
        setCategoryActionType,
        setSubCategoryActionType,
        setSelectedCategoryId,
        setSelectedSubCategoryId,
        setShowDeleteCategoryModal,
        //
        handleGetAllCategories,
        handleGetCategoryById,
        handleGetSubCategoryById,
        handleCreateCategory,
        handleUpdateCategory,
        handleUpdateSubCategory,
        handleDeleteCategory,
        handleToggleManageCategoryModal,
        handleCreateSubCategory,
        handleDeleteSubCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
}

export const useCategories = () => {
  const context = useContext(CategoriesContext);

  if (!context) {
    throw new Error('useCategories must be used within a CategoriesActionsProvider');
  }

  return context;
};

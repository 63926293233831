import styled from 'styled-components';
import { Input } from 'reactstrap';

export const SubCategoryBadgeWrapper = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;

  width: 100%;

  cursor: pointer;

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  &:hover > span {
    filter: brightness(0.7);
  }
`;

export const SubCategoryInput = styled(Input)`
  width: 20%;
`;

export const SubCategoryBadgeText = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.gray500};

  transition: all 0.3s ease-in-out;
`;

export const SubCategoryActionsButtons = styled.div<{ noSubCategoryAction: boolean }>`
  display: flex;
  align-items: center;

  opacity: ${({ noSubCategoryAction }) => (noSubCategoryAction ? 0 : 1)};
  visibility: ${({ noSubCategoryAction }) => (noSubCategoryAction ? 'hidden' : 'visible')};

  transition: all 0.3s ease-in-out;
`;

import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { aliasToFilterTypes, inputBlacklist, orderByCustomFilters } from '../constants';

export const useFilters = ({
  innerJoinColumns,
  handleFilters,
}: {
  innerJoinColumns?: string[];
  handleFilters?: (data: ColumnFiltersState) => void;
}) => {
  const [filterTypes, setFilterTypes] = useState<Record<string, any>>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [enableFilters, setEnableFilters] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    const transformItemId = (item, filterType) => {
      const id = filterType.filterAlias ?? item.id.replace(/_/g, '.');
      return `$${id}${filterType.type}$`;
    };

    const processItem = (item) => {
      if (filterTypes?.[item.id] && !inputBlacklist.includes(item.id)) {
        const filterType = filterTypes[item.id];
        const id = filterType.isJoinColumn
          ? transformItemId(item, filterType)
          : `${item.id}${filterType.type}`;

        return {
          ...item,
          id,
        };
      }

      const id = aliasToFilterTypes(item.id)[item.id] || aliasToFilterTypes(item.id)['default'];

      return {
        value: item.value,
        id,
      };
    };

    const newColumnFilters = columnFilters.filter((item) => item.id !== 'orderBy').map(processItem);

    const transformSortingItem = ({ id, desc }) => {
      if (innerJoinColumns?.includes(id)) {
        const fields = id.split('_');
        return `["${fields[0]}","${fields[1]}","${desc ? 'DESC' : 'ASC'}"]`;
      }

      return orderByCustomFilters(id, desc)[id] || orderByCustomFilters(id, desc)['default'];
    };

    const sortFilter = [
      ...newColumnFilters,
      {
        id: 'orderBy',
        value: `[${sorting.map(transformSortingItem).join(',')}]`,
      },
    ];

    if (handleFilters) {
      handleFilters(sorting.length > 0 ? sortFilter : newColumnFilters);
    }
  }, [columnFilters, sorting, filterTypes]);

  return {
    columnFilters,
    enableFilters,
    sorting,
    setColumnFilters,
    setEnableFilters,
    setSorting,
    setFilterTypes,
  };
};

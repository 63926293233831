import styled from 'styled-components';

export const CategoryBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 2;

  width: 100%;
`;
export const CategoryBadgeIcon = styled.span<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  padding: 8px;
  font-size: 16px;

  border-radius: 100%;
  color: #fff;
  background: ${({ color }) => color};
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`;
export const CategoryBadgeName = styled.span`
  font-size: 14px;
`;

import { InstanceError } from '@apptypes/instance';
import { generateToQueryString } from '@helpers/queryString';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import {
  TOTAL_VALUE_CANNOT_BE_NEGATIVE,
  TRANSACTION_ALREADY_APPROVED,
  VALUE_CANNOT_BE_NEGATIVE_OR_ZERO,
} from '@modules/Contract/constants';
import { updatePaymentType } from '@modules/Contract/submodules/Transactions/services';
import { ColumnFiltersState } from '@tanstack/react-table';

export const getAll = async (page = 1, size = 10, filterItems?: ColumnFiltersState) => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: `approved=false`,
    });
    const { data } = await instance.get(`/transaction-solicitation${queryString}`);

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transações não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const approveTransactionsByID = async (
  transactionID: string,
  status: boolean,
  paymentType: string,
) => {
  try {
    const { data } = await instance.put(`/transaction-solicitation/${transactionID}`, {
      approved: status,
    });

    await updatePaymentType({
      contractTransactionID: data.contracts_transactions_id,
      paymentType,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível aprovar a transação',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transação não encontrada',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para aprovar essa transação',
      [ERROR_STATUS_MESSAGE.TRANSACTION_ALREADY_APPROVED]: TRANSACTION_ALREADY_APPROVED,
      [ERROR_STATUS_MESSAGE.VALUE_CANNOT_BE_NEGATIVE_OR_ZERO]: VALUE_CANNOT_BE_NEGATIVE_OR_ZERO,
      [ERROR_STATUS_MESSAGE.TOTAL_VALUE_CANNOT_BE_NEGATIVE]: TOTAL_VALUE_CANNOT_BE_NEGATIVE,
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';

import withRouter from '@common/withRouter';
import { Page } from '@component/common/Page';
import { CustodyValue } from '../components/CustodyValue';
import { CustodyProjection } from '../components/CustodyProjection';
import { CustodyWithdraw } from '../components/CustodyWithdraw';
import { CustodyWithdrawRealocation } from '../components/CustodyWithdrawRealocation';
import { CustodyContractType } from '../components/CustodyContractType';
import { CustodyContractTypeValue } from '../components/CustodyContractTypeValue';
import { useQuery } from '@tanstack/react-query';
import { getCustodyReport } from '../services';
import { CustodyWithdrawRecurring } from '../components/CustodyWithdrawRecurring';
import { CustodyReportProps } from '../types';
import { custodyReportInitialData, getGraphData } from '../components/custodyUtils';
import classNames from 'classnames';

const Custody = () => {
  const [projectionMonths, setProjectionMonths] = useState<number>(1);

  const { data, isFetching } = useQuery<CustodyReportProps>({
    queryKey: ['custodyReport', projectionMonths],
    queryFn: () => getCustodyReport(projectionMonths),
    initialData: custodyReportInitialData,
    retry: 1,
  });

  const { typesOptions, valueOptions } = getGraphData(data);

  const handleChange = (value: string) => {
    setProjectionMonths(Number(value));
  };

  const navItems = [
    {
      title: 'Mensal',
      mounths: 1,
    },
    {
      title: 'Trimestral',
      mounths: 3,
    },
    {
      title: 'Semestral',
      mounths: 6,
    },
    {
      title: 'Anual',
      mounths: 12,
    },
  ];

  return (
    <Page
      title="Relatórios"
      breadcrumb={{
        title: 'Relatório de custódia',
        titleHref: '/reports',
        breadcrumbItem: 'Relatório de custódia',
      }}
    >
      <Row>
        <Col className="d-flex justify-content-end mb-4">
          <Nav pills className="custody-nav bg-light rounded" role="tablist">
            {navItems.map((item, index) => (
              <NavItem key={index}>
                <NavLink
                  className={classNames({
                    active: projectionMonths === item.mounths,
                  })}
                  onClick={() => handleChange(String(item.mounths))}
                >
                  {item.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row>
        <Col xxl="3" xl="6">
          <CustodyValue
            value={data.total_custody.value}
            notEntryProcessed={data.total_custody.investments_not_processed}
            notWithdrawProcessed={data.total_custody.withdrawals_not_processed}
            percentage={data.total_custody.porcentage}
            isLoading={isFetching}
          />
        </Col>
        <Col xxl="3" xl="6">
          <CustodyProjection value={data.custody_projection.value} isLoading={isFetching} />
        </Col>
        <Col xxl="2" xl="4">
          <CustodyWithdraw
            value={data.withdrawals.value}
            processed={data.withdrawals.processed}
            isLoading={isFetching}
          />
        </Col>
        <Col xxl="2" xl="4">
          <CustodyWithdrawRealocation
            value={data.withdrawals_relocation.value}
            isLoading={isFetching}
          />
        </Col>
        <Col xxl="2" xl="4">
          <CustodyWithdrawRecurring
            value={data.recurring_withdrawals.value}
            isLoading={isFetching}
          />
        </Col>
      </Row>
      <Row>
        <Col xxl="6" xl="12">
          <CustodyContractType isLoading={isFetching} options={typesOptions} />
        </Col>
        <Col xxl="6" xl="12">
          <CustodyContractTypeValue isLoading={isFetching} options={valueOptions} />
        </Col>
      </Row>
      s
    </Page>
  );
};

export default withRouter(Custody);

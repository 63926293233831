import React from 'react';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

const TRow = ({ row, dragActiveColumn, dragOver, isExpansibleData }: any): React.JSX.Element => {
  return (
    <>
      <tr key={row.id}>
        {row.getVisibleCells().map((cell, index) => (
          <td
            className={classNames(`${dragActiveColumn} ${index}`, {
              'table-draggable-active_tbody': dragActiveColumn === index,
              'table-draggle-hover': dragOver === index,
              'd-flex': index === row.getVisibleCells().length - 1 && isExpansibleData,
            })}
            colSpan={cell.colSpan}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    </>
  );
};

export default TRow;

import { ThemeType } from './themeTypes';

export const getTheme = (): ThemeType => {
  return {
    // Core Colors
    bodyBg: 'var(--bs-body-bg)',
    bodyColor: 'var(--bs-body-color)',
    primary: 'var(--bs-primary)',
    secondary: 'var(--bs-secondary)',
    success: 'var(--bs-success)',
    info: 'var(--bs-info)',
    warning: 'var(--bs-warning)',
    danger: 'var(--bs-danger)',
    light: 'var(--bs-light)',
    dark: 'var(--bs-dark)',

    // Gray Scale
    gray100: 'var(--bs-gray-100)',
    gray200: 'var(--bs-gray-200)',
    gray300: 'var(--bs-gray-300)',
    gray400: 'var(--bs-gray-400)',
    gray500: 'var(--bs-gray-500)',
    gray600: 'var(--bs-gray-600)',
    gray700: 'var(--bs-gray-700)',
    gray800: 'var(--bs-gray-800)',
    gray900: 'var(--bs-gray-900)',

    // Sidebar
    sidebarBg: 'var(--bs-sidebar-bg)',
    sidebarMenuItemColor: 'var(--bs-sidebar-menu-item-color)',
    sidebarMenuSubItemColor: 'var(--bs-sidebar-menu-sub-item-color)',
    sidebarMenuItemIconColor: 'var(--bs-sidebar-menu-item-icon-color)',
    sidebarMenuItemHoverColor: 'var(--bs-sidebar-menu-item-hover-color)',
    sidebarMenuItemActiveColor: 'var(--bs-sidebar-menu-item-active-color)',
    sidebarDarkBg: 'var(--bs-sidebar-dark-bg)',
    sidebarDarkMenuItemColor: 'var(--bs-sidebar-dark-menu-item-color)',
    sidebarDarkMenuSubItemColor: 'var(--bs-sidebar-dark-menu-sub-item-color)',
    sidebarDarkMenuItemIconColor: 'var(--bs-sidebar-dark-menu-item-icon-color)',
    sidebarDarkMenuItemHoverColor: 'var(--bs-sidebar-dark-menu-item-hover-color)',
    sidebarDarkMenuItemActiveColor: 'var(--bs-sidebar-dark-menu-item-active-color)',

    // Header
    headerHeight: 'var(--bs-header-height)',
    headerBg: 'var(--bs-header-bg)',
    headerItemColor: 'var(--bs-header-item-color)',
    headerDarkBg: 'var(--bs-header-dark-bg)',
    headerDarkItemColor: 'var(--bs-header-dark-item-color)',
    topbarSearchBg: 'var(--bs-topbar-search-bg)',

    // Footer
    footerHeight: 'var(--bs-footer-height)',
    footerBg: 'var(--bs-footer-bg)',
    footerColor: 'var(--bs-footer-color)',

    // Horizontal Nav
    topnavBg: 'var(--bs-topnav-bg)',
    menuItemColor: 'var(--bs-menu-item-color)',
    menuItemActiveColor: 'var(--bs-menu-item-active-color)',

    // Right Sidebar
    rightbarWidth: 'var(--bs-rightbar-width)',

    // Layout
    sidebarWidth: 'var(--bs-sidebar-width)',
    sidebarCollapsedWidth: 'var(--bs-sidebar-collapsed-width)',
    sidebarWidthSm: 'var(--bs-sidebar-width-sm)',
    layoutCollapsedMinHeight: 'var(--bs-layout-collapsed-min-height)',

    // Display
    displayNone: 'var(--bs-display-none)',
    displayBlock: 'var(--bs-display-block)',

    // Font
    fontWeightMedium: 'var(--bs-font-weight-medium)',
    fontWeightSemiBold: 'var(--bs-font-weight-semibold)',
    fontFamilySansSerif: 'var(--bs-font-family-sans-serif)',
    fontFamilyMonospace: 'var(--bs-font-family-monospace)',
    fontSizeBase: 'var(--bs-font-size-base)',
    fontSizeSm: 'var(--bs-font-size-sm)',
    fontSizeLg: 'var(--bs-font-size-lg)',
    lineHeightBase: 'var(--bs-line-height-base)',
    headingsColor: 'var(--bs-headings-color)',

    // Table
    tableHeadBg: 'var(--bs-table-head-bg)',
    tableDarkBorderColor: 'var(--bs-table-dark-border-color)',

    // Miscellaneous Colors
    customWhite: 'var(--bs-custom-white)',
    borderColor: 'var(--bs-gray-300)',
    borderRadius: 'var(--bs-border-radius)',
    boxShadow: 'var(--bs-box-shadow)',
    modalBg: 'var(--bs-modal-bg)',
    dropdownBg: 'var(--bs-dropdown-bg)',
    inputBg: 'var(--bs-input-bg)',
    inputBorderColor: 'var(--bs-input-border-color)',

    // Button Colors
    btnLightBg: 'var(--bs-btn-light-bg)',
    btnDarkBg: 'var(--bs-btn-dark-bg)',
    btnHoverBg: 'var(--bs-btn-hover-bg)',

    // Alerts
    alertSuccessBg: 'var(--bs-alert-success-bg)',
    alertDangerBg: 'var(--bs-alert-danger-bg)',

    // Colors Palette
    blue: 'var(--bs-blue)',
    blue2: 'var(--bs-blue2)',
    indigo: 'var(--bs-indigo)',
    purple: 'var(--bs-purple)',
    pink: 'var(--bs-pink)',
    red: 'var(--bs-red)',
    orange: 'var(--bs-orange)',
    yellow: 'var(--bs-yellow)',
    green: 'var(--bs-green)',
    teal: 'var(--bs-teal)',
    cyan: 'var(--bs-cyan)',
    black: 'var(--bs-black)',
    white: 'var(--bs-white)',

    // Spacing
    spacer: 'var(--bs-spacer)',
    spacers: {
      '0': 'var(--bs-spacer-0)',
      '1': 'var(--bs-spacer-1)',
      '2': 'var(--bs-spacer-2)',
      '3': 'var(--bs-spacer-3)',
      '4': 'var(--bs-spacer-4)',
      '5': 'var(--bs-spacer-5)',
    },

    // Border
    borderWidth: 'var(--bs-border-width)',

    // Grid
    gridColumns: 'var(--bs-grid-columns)',
    gridGutterWidth: 'var(--bs-grid-gutter-width)',

    // Shadows
    boxShadowSm: 'var(--bs-box-shadow-sm)',
    boxShadowLg: 'var(--bs-box-shadow-lg)',

    // Z-Index
    zIndexDropdown: 'var(--bs-zindex-dropdown)',
    zIndexSticky: 'var(--bs-zindex-sticky)',
    zIndexFixed: 'var(--bs-zindex-fixed)',
    zIndexModalBackdrop: 'var(--bs-zindex-modal-backdrop)',
    zIndexModal: 'var(--bs-zindex-modal)',

    // Form
    inputFocusBorderColor: 'var(--bs-input-focus-border-color)',
    inputFocusBoxShadow: 'var(--bs-input-focus-box-shadow)',
    inputPlaceholderColor: 'var(--bs-input-placeholder-color)',

    // Pagination
    paginationBg: 'var(--bs-pagination-bg)',
    paginationHoverBg: 'var(--bs-pagination-hover-bg)',
    paginationActiveBg: 'var(--bs-pagination-active-bg)',

    // Accordion
    accordionButtonActiveBg: 'var(--bs-accordion-button-active-bg)',

    // Dropdown
    dropdownLinkHoverBg: 'var(--bs-dropdown-link-hover-bg)',

    // Progress Bar
    progressBg: 'var(--bs-progress-bg)',
    progressBarBg: 'var(--bs-progress-bar-bg)',

    // Carousel
    carouselControlColor: 'var(--bs-carousel-control-color)',

    // Spinner
    spinnerWidth: 'var(--bs-spinner-width)',

    // Close Button
    btnCloseColor: 'var(--bs-btn-close-color)',

    // Offcanvas
    offcanvasBg: 'var(--bs-offcanvas-bg)',

    // Tooltip
    tooltipBg: 'var(--bs-tooltip-bg)',

    // Breadcrumbs
    breadcrumbDividerColor: 'var(--bs-breadcrumb-divider-color)',

    // Card
    cardBg: 'var(--bs-card-bg)',
    cardBorderColor: 'var(--bs-card-border-color)',
    cardBoxShadow: 'var(--bs-card-box-shadow)',

    // Navs
    navLinkPaddingY: 'var(--bs-nav-link-padding-y)',
    navLinkPaddingX: 'var(--bs-nav-link-padding-x)',

    // Navbar
    navbarPaddingY: 'var(--bs-navbar-padding-y)',
    navbarLightColor: 'var(--bs-navbar-light-color)',
    navbarDarkColor: 'var(--bs-navbar-dark-color)',

    // List Group
    listGroupBg: 'var(--bs-list-group-bg)',
    listGroupActiveBg: 'var(--bs-list-group-active-bg)',

    // Alerts
    alertPaddingY: 'var(--bs-alert-padding-y)',
    alertPaddingX: 'var(--bs-alert-padding-x)',

    // Modals
    modalInnerPadding: 'var(--bs-modal-inner-padding)',
    modalBackdropBg: 'var(--bs-modal-backdrop-bg)',

    // Badges
    badgeFontSize: 'var(--bs-badge-font-size)',
    badgeFontWeight: 'var(--bs-badge-font-weight)',
    badgeBorderRadius: 'var(--bs-badge-border-radius)',

    // Progress Bars
    progressHeight: 'var(--bs-progress-height)',

    // Form Tooltips
    formFeedbackMarginTop: 'var(--bs-form-feedback-margin-top)',

    // Placeholders
    placeholderOpacityMax: 'var(--bs-placeholder-opacity-max)',

    // Buttons
    btnPaddingY: 'var(--bs-btn-padding-y)',
    btnPaddingX: 'var(--bs-btn-padding-x)',
  };
};

import React from 'react';
import { Row } from '@tanstack/react-table';
import TRow from './TRow';

export const Tbody = ({
  dragActiveColumn,
  dragOver,
  rows,
  customRow,
}: {
  dragActiveColumn?: number;
  dragOver?: number;
  rows: Array<Row<unknown>>;
  customRow?: React.ReactElement;
}) => {
  const Component: any = customRow || TRow;

  return (
    <tbody>
      {rows.map((row: any) => (
        <Component
          key={row.id}
          row={row}
          dragActiveColumn={dragActiveColumn}
          dragOver={dragOver}
          isExpansibleData
        />
      ))}
    </tbody>
  );
};

import React, { useCallback, useState } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';

import { useCategories } from '@modules/Finance/contexts/CategoriesContext';

import * as S from './styles';

import { ICategoryModalProps } from './types';
export const DeleteCategoryModal = ({ toggleModal, showModal, category }: ICategoryModalProps) => {
  // * [Contexts] * //
  const { categories, loadingDeleteCategory, handleDeleteCategory } = useCategories();

  // * [States] * //
  const [newCategoryForTransactions, setNewCategoryForTransactions] = useState('');

  // * [Variables] * //
  const FILTERED_CATEGORIES = categories?.filter(
    (categoryItem) => categoryItem?.id !== category?.id,
  );
  const DISABLE_DELETE_BUTTON = category?.hasTransactions ? !newCategoryForTransactions : false;
  const CATEGORY_DELETION_WARNING_MESSAGES = {
    default: [
      'Excluir esta categoria é uma ação permanente e não pode ser desfeita.',
      'Se houver subcategorias, elas também serão removidas.',
    ],
    withTransactions: [
      'A categoria possui transações associadas. Por favor, mova-as para outra categoria antes de excluir.',
    ],
  };

  // * [Functions] * //
  const getWarningMessages = () => {
    return category?.hasTransactions
      ? CATEGORY_DELETION_WARNING_MESSAGES.withTransactions
      : CATEGORY_DELETION_WARNING_MESSAGES.default;
  };

  const handleDeleteCategoryAction = useCallback(() => {
    handleDeleteCategory(category?.id, newCategoryForTransactions);
  }, [newCategoryForTransactions]);

  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered>
      <S.CategoryModalHeader toggle={toggleModal}>
        Tem certeza que deseja excluir?
      </S.CategoryModalHeader>

      <ModalBody>
        {getWarningMessages().map((message, index) => (
          <S.CategoryModalDescription key={index}>{message}</S.CategoryModalDescription>
        ))}

        {category?.hasTransactions && (
          <>
            <S.CategoryModalSelectLabel>Selecione uma categoria:</S.CategoryModalSelectLabel>

            <S.CategoryModalSelect
              type="select"
              value={newCategoryForTransactions}
              onChange={(e) => setNewCategoryForTransactions(e.target.value)}
            >
              <S.CategoryModalSelectOption value="">Selecione...</S.CategoryModalSelectOption>
              {FILTERED_CATEGORIES?.map((category) => (
                <S.CategoryModalSelectOption key={category.id} value={category.id}>
                  {category.name}
                </S.CategoryModalSelectOption>
              ))}
            </S.CategoryModalSelect>
          </>
        )}
      </ModalBody>

      <S.CategoryModalFooter>
        <S.CategoryModalButtonWrapper>
          <S.CategoryModalButton
            color="primary"
            outline={true}
            onClick={toggleModal}
            disabled={false}
          >
            Cancelar
          </S.CategoryModalButton>

          <S.CategoryModalButton
            color="danger"
            onClick={handleDeleteCategoryAction}
            disabled={DISABLE_DELETE_BUTTON}
          >
            {loadingDeleteCategory ? <Spinner size="sm" color="light" /> : 'Excluir'}
          </S.CategoryModalButton>
        </S.CategoryModalButtonWrapper>
      </S.CategoryModalFooter>
    </Modal>
  );
};

import styled from 'styled-components';

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 16px;

  border-bottom: 2px solid ${(props) => props.theme.borderColor};
`;

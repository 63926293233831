import styled from 'styled-components';

export const CategoryActionsContainer = styled.div`
  position: relative;
`;

export const CategoryActionMenuButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
`;
export const CategoryActionsButtons = styled.div`
  position: absolute;
  right: 5%;
  z-index: 1;

  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;

  padding: 8px;

  background: ${(props) => props.theme.gray100};
  box-shadow: ${(props) => props.theme.boxShadowSm};
  border-radius: 8px;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

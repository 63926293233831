import { Button, Input, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

export const CategoryModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;
export const CategoryModalDescription = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${({ theme }) => theme.gray600};
`;
export const CategoryModalSelectLabel = styled(CategoryModalDescription)`
  margin-top: 32px;
`;
export const CategoryModalSelect = styled(Input)``;

export const CategoryModalSelectOption = styled.option``;
export const CategoryModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;

  padding-bottom: 32px;

  border-top: none;
`;
export const CategoryModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  width: 100%;
`;
export const CategoryModalButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
`;

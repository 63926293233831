import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import withRouter from '@component/common/withRouter';
import LinkPartnerForm from '@modules/Contract/submodules/Partner/components/LinkPartnerForm';
import { IContractCreate, ILinkPartner } from '@modules/Contract/types';
import { getByID } from '@modules/Contract/services';
import Spinners from '@component/common/Spinner';
import { linkPartner } from '@modules/Contract/submodules/Partner/services';
import { REDIRECT_TIMEOUT } from '@helpers/constants';
import { Detail } from '@modules/Contract/submodules/Partner/components/Detail';
import { EXPIRATION_DATE_MANDATORY, PARTNER_MANDATORY, PERCENTAGE_MANDATORY } from '../constants';
import moment from 'moment';

const LinkPartner = ({ router }: IWithRouter & IContractCreate) => {
  const { contractID } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: ['contract', contractID],
    queryFn: () => getByID(contractID || ''),
    retry: 1,
  });

  const { mutate, error, isSuccess, isPending } = useMutation({
    mutationFn: (payload: {
      contractID: string;
      partnerID: string;
      expirationDate: string;
      partnerPercentage: number;
    }) =>
      linkPartner(
        payload.contractID,
        payload.partnerID,
        payload.expirationDate,
        payload.partnerPercentage,
      ),
    onSuccess: () => {
      setTimeout(() => {
        router.navigate(`/contracts/${contractID}`);
      }, REDIRECT_TIMEOUT);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner: {
        value: '',
        label: '',
      },
      expirationDate: moment().add(5, 'months').format('YYYY-MM-DD'),
      partnerPercentage: 0,
    },
    validationSchema: Yup.object({
      partner: Yup.object({
        value: Yup.string().required(PARTNER_MANDATORY),
        label: Yup.string().required(PARTNER_MANDATORY),
      }).required(PARTNER_MANDATORY),
      expirationDate: Yup.string().required(EXPIRATION_DATE_MANDATORY),
      partnerPercentage: Yup.number().required(PERCENTAGE_MANDATORY),
    }),
    onSubmit: async (payload: ILinkPartner) => {
      mutate({
        contractID: contractID || '',
        partnerID: payload.partner.value,
        expirationDate: payload.expirationDate,
        partnerPercentage: payload.partnerPercentage,
      });
    },
  });

  return (
    <Page
      title="Vincular acessor ao contrato"
      breadcrumb={{
        title: 'Contratos',
        titleHref: `/contracts`,
        breadcrumbItem: 'Vincular acessor ao contato',
      }}
    >
      <Row>
        <Col lg={12}>
          {isFetching ? (
            <Spinners />
          ) : (
            <>
              <Detail
                identificationNumber={data?.identification_number || ''}
                profitabilityGroupDescription={data?.profitability_group?.description || ''}
                profitabilityGroupPercentage={data?.profitability_group?.percentage || ''}
                contractID={contractID || ''}
              />
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Vincular acessor ao contrato</CardTitle>
                  <div className="p-2 mt-3">
                    <LinkPartnerForm
                      loading={isPending}
                      validation={validation}
                      registrationError={error?.message}
                      showAlert={isSuccess}
                    />
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(LinkPartner);

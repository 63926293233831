import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';

const CopyRow = ({ id, value }: { id: string; value: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      toast.success('Conteúdo copiado com sucesso!', {
        theme: 'colored',
        autoClose: 1000,
        onClose: () => setIsCopied(false),
      });
    }
  }, [isCopied]);

  return (
    <>
      <p
        className="text-muted mb-0 table-description"
        id={`row-${id}`}
        onClick={() => {
          navigator.clipboard.writeText(value);
          setIsCopied(true);
        }}
      >
        {value}
      </p>
      <UncontrolledTooltip placement="right" target={`row-${id}`}>
        {value}
      </UncontrolledTooltip>
    </>
  );
};

export default CopyRow;

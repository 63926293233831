export enum TransactionTypeEnum {
  Income = 'R',
  Expense = 'D',
}

export const enum SubCategoryActionTypeEnum {
  None = 'None',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
}

import styled from 'styled-components';
import { Spinner } from 'reactstrap';

export const ActionButton = styled.button<{ color: string; size?: string }>`
  display: flex;

  padding: 8px;

  font-size: ${({ size }) => size || '14px'};

  border: none;
  background: none;
  border-radius: 8px;
  color: ${({ color }) => color || 'var(--gray-500)'};

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(0.7);
  }
`;

export const ActionButtonIcon = styled.span`
  margin-right: 4px;
`;

export const ActionButtonSpinner = styled(Spinner)`
  margin: 0 24px;
`;

import { moneyTransform } from '@helpers/transforms/money';
import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';

const badgeValues: Record<string, { value: string; color: string }> = {
  APORTE: { value: 'Aporte', color: 'success' },
  APORTE_LUCRO: { value: 'Aporte do Lucro', color: 'primary' },
  RETIRADA: { value: 'Retirada', color: 'danger' },
  RETIRADA_LUCRO: { value: 'Retirada do Lucro', color: 'danger' },
};

export const paymentValues: Record<string, string> = {
  DINHEIRO: 'Dinheiro',
  PIX: 'PIX',
  TED: 'TED',
  BOLETO: 'Boleto',
  CHEQUE: 'Cheque',
  A_NEGOCIAR: 'A negociar',
};

export const buildColumns = (isAdmin: boolean, clientID: string, contractID: string) => {
  const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor('type', {
      cell: ({ row, getValue }: any) => (
        <Badge color={badgeValues[getValue()]?.color} className="font-size-14" pill>
          {badgeValues[getValue()]?.value}
        </Badge>
      ),
      header: 'Tipo de transação',
      size: 5,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'APORTE', label: 'Aporte' },
          { value: 'APORTE_LUCRO', label: 'Aporte do Lucro' },
          { value: 'RETIRADA', label: 'Retirada' },
          { value: 'RETIRADA_LUCRO', label: 'Retirada do Lucro' },
        ],
      },
    }),
    columnHelper.accessor('value', {
      cell: ({ row, getValue }: any) => {
        const prefix = ['APORTE', 'APORTE_LUCRO'].includes(row.original.type) ? '+' : '-';
        return (
          <h5 className="font-size-14 mb-1">
            <Link
              to="#"
              className={classNames('text-dark', {
                'positive-value':
                  row.original.type === 'APORTE' || row.original.type === 'APORTE_LUCRO',
                'negative-value':
                  row.original.type === 'RETIRADA' || row.original.type === 'RETIRADA_LUCRO',
              })}
            >
              {prefix} {moneyTransform(getValue())}
            </Link>
          </h5>
        );
      },
      header: 'Valor',
      size: 15,
    }),
    columnHelper.accessor('total_value', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          <Link to="#" className="text-dark">
            {row.original.type !== 'APORTE_LUCRO' ? moneyTransform(getValue()) : '-'}
          </Link>
        </h5>
      ),
      header: 'Valor total',
      size: 15,
    }),
    columnHelper.accessor('payment_type', {
      cell: ({ row, getValue }: any) => (
        <Badge color="primary" className="font-size-14" pill>
          {paymentValues[getValue()]}
        </Badge>
      ),
      header: 'Tipo de pagamento',
      size: 15,
      meta: {
        filterType: 'select',
        filterOptions: [
          { value: '', label: 'Selecione...' },
          { value: 'DINHEIRO', label: 'Dinheiro' },
          { value: 'PIX', label: 'PIX' },
          { value: 'TED', label: 'TED' },
          { value: 'BOLETO', label: 'Boleto' },
          { value: 'CHEQUE', label: 'Cheque' },
          { value: 'A_NEGOCIAR', label: 'A negociar' },
        ],
      },
    }),
    columnHelper.accessor('transaction_date', {
      cell: ({ row, getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data do aporte/retirada',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('effective_date', {
      cell: ({ row, getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data efetiva',
      size: 28,
      meta: {
        disableFilter: true,
      },
    }),
    columnHelper.accessor('confirmed_at', {
      cell: ({ getValue }: any) => (
        <p className="text-muted mb-0">{moment(getValue()).utcOffset(6).format('DD/MM/YYYY')}</p>
      ),
      header: 'Data confirmação pagamento',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('created_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Data de criação',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('updated_at', {
      cell: ({ row, getValue }: any) => (
        <h5 className="font-size-14 mb-1">
          {moment(getValue()).utcOffset(-6).format('DD/MM/YYYY HH:mm:ss')}
        </h5>
      ),
      header: 'Última atualização',
      size: 10,
      meta: {
        filterType: 'date_range',
        disableFilterType: true,
      },
    }),
    columnHelper.accessor('actions', {
      cell: ({ row }: any) => {
        if (!isAdmin) {
          return;
        }

        return (
          <div className="d-flex justify-content-end gap-3">
            <Link
              to={`/contracts/${contractID}/contracts-transactions/${row.original.id}/contracts-transactions-files`}
              className="btn btn-outline-warning btn-sm"
            >
              <i className="mdi mdi-eye font-size-14" id="edittooltip" /> Comprovantes
            </Link>
            <Link
              to={`/contracts/${contractID}/contracts-transactions/${row.original.id}/${clientID}`}
              className="btn btn-primary btn-sm"
            >
              <i className="mdi mdi-pencil font-size-14" id="edittooltip" /> Editar
            </Link>
          </div>
        );
      },
      header: '',
      enableSorting: false,
      size: 25,
      meta: {
        disableFilter: true,
      },
    }),
  ];
};

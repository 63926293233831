type CalculateWorkingDays = (year: number, month: number, holidays?: string[]) => number;

export const calculateWorkingDays: CalculateWorkingDays = (year, month, holidays = []) => {
  const totalDays = new Date(year, month, 0).getDate();

  let workingDays = 0;

  for (let day = 1; day <= totalDays; day++) {
    const date = new Date(year, month - 1, day);
    const dayOfWeek = date.getDay();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      const formattedDate = date.toISOString().split('T')[0];

      if (!holidays.includes(formattedDate)) {
        workingDays++;
      }
    }
  }

  return workingDays;
};

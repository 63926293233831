import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import '@assets/scss/theme.scss';

import { authProtectedRoutes, publicRoutes } from '@routes/allRoutes';
import AuthProtected from '@routes/AuthProtected';

import VerticalLayout from './components/Layouts/VerticalLayout';
import NonAuthLayout from './components/Layouts/NonLayout';
import CategoriesActionsProvider from '@modules/Finance/contexts/CategoriesContext';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import { getTheme } from 'theme';

const App = () => {
  const [theme, setTheme] = useState(getTheme());
  const layoutMode = useSelector((state: any) => state.Layout.layoutMode);

  useEffect(() => {
    setTheme(getTheme());
  }, [layoutMode]);

  return (
    <ThemeProvider theme={theme}>
      <CategoriesActionsProvider>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            />
          ))}
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              key={idx}
              element={
                <AuthProtected>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
              }
            />
          ))}
        </Routes>
      </CategoriesActionsProvider>
    </ThemeProvider>
  );
};

export default App;

import React from 'react';

import * as S from './styles';

import { IAccordionRootProps } from './types';

export const AccordionRoot = ({ children, hasSubCategories }: IAccordionRootProps) => {
  return (
    <S.AccordionRootContainer hasSubCategories={hasSubCategories}>
      {children}
    </S.AccordionRootContainer>
  );
};

import React, { useRef, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { useClickAway } from 'react-use';

import * as S from './styles';

import { IColorPickerProps } from './types';

export const CustomColorPicker = ({ color, setColor }: IColorPickerProps) => {
  // * [States] * //
  const [showPicker, setShowPicker] = useState(false);

  // * [Refs] * //
  const pickerRef = useRef(null);

  // * [Functions] * //
  const handleColorChange = (colorResult: ColorResult) => {
    setColor(colorResult.hex);
  };

  // * [Hooks] * //
  useClickAway(pickerRef, () => {
    setShowPicker(false);
  });

  return (
    <S.PickerWrapper>
      <S.ColorPreview color={color} onClick={() => setShowPicker(!showPicker)} />

      <S.ColorPreviewWrapper>
        {showPicker && (
          <S.StyledPicker ref={pickerRef}>
            <ChromePicker color={color} onChange={handleColorChange} disableAlpha={true} />
          </S.StyledPicker>
        )}
        <S.ColorPreviewBox color={color} />
      </S.ColorPreviewWrapper>
    </S.PickerWrapper>
  );
};

import { InstanceError } from '@apptypes/instance';
import { ERROR_STATUS_MESSAGE } from '@helpers/request/constants';
import instance from '@helpers/request/instance';
import { ITransactionExport, ITransactionsCreate } from '@modules/Contract/types';
import { transformMoneyToNumber } from '@helpers/transforms/money';
import {
  CLIENT_DOES_NOT_HAVE_ADDRESSES,
  CLIENT_DOES_NOT_HAVE_CNPJ_OR_IE_OR_IM,
  CLIENT_DOES_NOT_HAVE_CONTACTS,
  CLIENT_DOES_NOT_HAVE_CPF_OR_RG,
  CLIENT_DOES_NOT_HAVE_DOCUMENTS,
  CLIENT_DOES_NOT_HAVE_EMAIL_OR_PHONE,
  TOTAL_VALUE_CANNOT_BE_NEGATIVE,
  TRANSACTION_DATE_GREATER_THAN_EFFECTIVE_DATE,
  VALUE_CANNOT_BE_NEGATIVE_OR_ZERO,
} from '@modules/Contract/constants';
import { IPagination } from '@apptypes/pagination';
import { ColumnFiltersState } from '@tanstack/react-table';
import { generateToQueryString } from '@helpers/queryString';
import { getCookie } from '@helpers/cookies';
import { AUTH_COOKIE_NAME } from '@helpers/constants';

export const getAll = async (
  contractID: string,
  page: number,
  size: number,
  filterItems?: ColumnFiltersState,
): Promise<IPagination<ITransactionsCreate>> => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: contractID ? `&contract_id=${contractID}` : '',
    });
    const { data } = await instance.get(`/contracts-transactions${queryString}`);
    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transações não encontradas',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const getByID = async (transactionID: string): Promise<ITransactionsCreate> => {
  try {
    const { data } = await instance.get(`/contracts-transactions/${transactionID}`);

    data.receiptFile = data.receipt_path;
    data.receiptName = data.receipt_name;

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transação não encontrada',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const create = async (
  contractID: string,
  clientID: string,
  payload: ITransactionsCreate,
): Promise<ITransactionsCreate> => {
  try {
    const { data } = await instance.post('/contracts-transactions', {
      ...payload,
      value: transformMoneyToNumber(payload.value),
      contract_id: contractID,
      client_id: clientID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível criar a transação',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transação não encontrada',
      [ERROR_STATUS_MESSAGE.VALUE_CANNOT_BE_NEGATIVE_OR_ZERO]: VALUE_CANNOT_BE_NEGATIVE_OR_ZERO,
      [ERROR_STATUS_MESSAGE.TOTAL_VALUE_CANNOT_BE_NEGATIVE]: TOTAL_VALUE_CANNOT_BE_NEGATIVE,
      [ERROR_STATUS_MESSAGE.TRANSACTION_DATE_GREATER_THAN_EFFECTIVE_DATE]:
        TRANSACTION_DATE_GREATER_THAN_EFFECTIVE_DATE,
      [ERROR_STATUS_MESSAGE.CLIENT_DOES_NOT_HAVE_CONTACTS]: CLIENT_DOES_NOT_HAVE_CONTACTS,
      [ERROR_STATUS_MESSAGE.CLIENT_DOES_NOT_HAVE_ADDRESSES]: CLIENT_DOES_NOT_HAVE_ADDRESSES,
      [ERROR_STATUS_MESSAGE.CLIENT_DOES_NOT_HAVE_DOCUMENTS]: CLIENT_DOES_NOT_HAVE_DOCUMENTS,
      [ERROR_STATUS_MESSAGE.CLIENT_DOES_NOT_HAVE_EMAIL_OR_PHONE]:
        CLIENT_DOES_NOT_HAVE_EMAIL_OR_PHONE,
      [ERROR_STATUS_MESSAGE.CLIENT_DOES_NOT_HAVE_CPF_OR_RG]: CLIENT_DOES_NOT_HAVE_CPF_OR_RG,
      [ERROR_STATUS_MESSAGE.CLIENT_DOES_NOT_HAVE_CNPJ_OR_IE_OR_IM]:
        CLIENT_DOES_NOT_HAVE_CNPJ_OR_IE_OR_IM,
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    const err = error as InstanceError;
    const messages = err.status.split(',').reduce((acc, cur) => {
      if (errorMessage[cur]) {
        return acc + errorMessage[cur] + ';';
      }

      return acc;
    }, '');

    throw new Error(messages);
  }
};

export const update = async (
  transactionID: string,
  clientID: string,
  contractID: string,
  payload: ITransactionsCreate,
): Promise<ITransactionsCreate> => {
  try {
    const { data } = await instance.put(`/contracts-transactions/${transactionID}`, {
      ...payload,
      value: transformMoneyToNumber(payload.value),
      contract_id: contractID,
      client_id: clientID,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar a transação',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'transação não encontrada',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para editar a transação',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const updatePaymentType = async ({
  contractTransactionID,
  paymentType,
  observation,
}: {
  contractTransactionID: string;
  paymentType: string;
  observation?: string;
}): Promise<ITransactionsCreate> => {
  try {
    const { data } = await instance.put(`/contracts-transactions/${contractTransactionID}`, {
      payment_type: paymentType,
      observation,
    });

    return data;
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível editar o tipo de pagamento',
      [ERROR_STATUS_MESSAGE.CANNOT_UPDATE_CONTRACT_TRANSACTION]:
        'Não foi possível editar o tipo de pagamento',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const deleteByID = async (transactionID: string) => {
  try {
    const { data } = await instance.get(`/contracts-transactions?contract_id=${transactionID}`);

    return data;
  } catch (error) {
    const errorMessage: Record<string, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível deletar a transação',
      [ERROR_STATUS_MESSAGE.UNAUTHORIZED]: 'Você não tem permissão para deletar a transação',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const downloadFile = async (transactionID: string, receiptType: string) => {
  try {
    const file = await fetch(
      `${process.env.BASE_URL}/contracts-transactions/${transactionID}/download`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const contentType = {
      PDF: 'application/pdf',
      PNG: 'image/png',
      JPEG: 'image/jpeg',
    };

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], { type: contentType[receiptType.toUpperCase()] });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportData = async (
  page = 1,
  size = 1000,
  contractId: string,
  filterItems?: ColumnFiltersState,
) => {
  try {
    const queryString = generateToQueryString({
      page,
      size,
      filterItems,
      otherParams: `contract_id=${contractId}`,
    });
    const file = await fetch(
      `${process.env.BASE_URL}/contracts-transactions/report/export${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const response = await file.arrayBuffer();
    const fileBlog = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

export const exportAll = async ({
  type,
  paymentType,
  transactionDate,
  contractActive,
  recurringWithdrawal,
}: ITransactionExport) => {
  try {
    let queryString = '?';

    if (type) {
      queryString = `${queryString}type=${type}&`;
    }

    if (paymentType) {
      queryString = `${queryString}payment_type=${paymentType}&`;
    }

    if (contractActive) {
      queryString = `${queryString}$contract.active$=${contractActive}&`;
    }

    if (recurringWithdrawal) {
      queryString = `${queryString}$contract.recurring_withdrawal$=${recurringWithdrawal}&`;
    }

    if (transactionDate) {
      queryString = `${queryString}transaction_date_between=${transactionDate}&`;
    }

    const file = await fetch(
      `${process.env.BASE_URL}/contracts-transactions/report/export${queryString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: getCookie(AUTH_COOKIE_NAME),
        } as any,
      },
    );

    const response = await file.arrayBuffer();

    const fileBlog = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    });

    return URL.createObjectURL(fileBlog);
  } catch (error) {
    const errorMessage: Record<number, string> = {
      [ERROR_STATUS_MESSAGE.BAD_REQUEST]: 'Não foi possível buscar o arquivo',
      [ERROR_STATUS_MESSAGE.NOT_FOUND]: 'arquivo não encontrado',
      [ERROR_STATUS_MESSAGE.INTERNAL_SERVER]: 'Erro inesperado',
    };

    throw new Error(
      errorMessage[(error as InstanceError).status] ??
        errorMessage[ERROR_STATUS_MESSAGE.INTERNAL_SERVER],
    );
  }
};

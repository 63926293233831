import React, { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useTheme } from 'styled-components';

import { useCategories } from '@modules/Finance/contexts/CategoriesContext';

import { ActionButton } from '../ActionButton';
import { DeleteCategoryModal } from '../DeleteCategoryModal';

import * as S from './styles';

import { ICategoryActionsProps } from './types';
import { SubCategoryActionTypeEnum } from '@modules/Finance/ultis/categories/enums';

export const CategoryActions = ({ category }: ICategoryActionsProps) => {
  // * [Theme] * //
  const theme = useTheme();

  // * [Contexts] * //
  const {
    showDeleteCategoryModal,
    //
    setSelectedCategoryId,
    setShowDeleteCategoryModal,
    setSubCategoryActionType,
    //
    handleToggleManageCategoryModal,
    handleGetCategoryById,
  } = useCategories();

  // * [States] * //
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  // * [Refs] * //
  const categoryActionsRef = useRef(null);

  // * [Hooks] * //
  useClickAway(categoryActionsRef, () => {
    if (!showDeleteCategoryModal) {
      setActiveCategory(null);
    }
  });

  // * [Variables] * //
  const SHOW_DELETE_CATEGORY_MODAL = showDeleteCategoryModal && activeCategory === category?.id;

  // * [Functions] * //
  const handleShowCategoryActions = useCallback((id: string) => {
    setActiveCategory((prev) => (prev === id ? null : id));
  }, []);

  const handleShowDeleteCategoryModal = useCallback(() => {
    setShowDeleteCategoryModal((prev) => !prev);
  }, []);

  const handleShowEditCategoryModal = useCallback(async (id: string) => {
    handleToggleManageCategoryModal();
    handleGetCategoryById(id);
    setActiveCategory(null);
  }, []);

  const handleAddSubCategoryActions = useCallback((id: string) => {
    setSelectedCategoryId((prev) => (prev === id ? null : id));
    setActiveCategory(null);
    setSubCategoryActionType(SubCategoryActionTypeEnum.Create);
  }, []);

  return (
    <S.CategoryActionsContainer ref={categoryActionsRef}>
      <ActionButton
        onClick={() => handleShowCategoryActions(category?.id)}
        iconClass="mdi mdi-dots-vertical"
        text=""
        textColor={theme.gray500}
        size="24px"
      />

      {activeCategory === category?.id && (
        <S.CategoryActionsButtons>
          <ActionButton
            onClick={() => handleShowEditCategoryModal(category?.id)}
            iconClass="mdi mdi-pencil"
            text="editar"
            textColor={theme.info}
          />
          <ActionButton
            onClick={handleShowDeleteCategoryModal}
            iconClass="mdi mdi-delete"
            text="excluir"
            textColor={theme.danger}
          />
          <ActionButton
            onClick={() => handleAddSubCategoryActions(category?.id)}
            iconClass="mdi mdi-plus"
            text="subcategoria"
            textColor={theme.info}
          />
        </S.CategoryActionsButtons>
      )}

      {SHOW_DELETE_CATEGORY_MODAL && (
        <DeleteCategoryModal
          category={category}
          showModal={showDeleteCategoryModal}
          toggleModal={handleShowDeleteCategoryModal}
        />
      )}
    </S.CategoryActionsContainer>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { useTheme } from 'styled-components';

import { distinctPastelColors, mdiIconsWebfont } from '@modules/Finance/constants/categories';
import { useCategories } from '@modules/Finance/contexts/CategoriesContext';
import { TransactionTypeEnum } from '@modules/Finance/ultis/categories/enums';

import { CustomColorPicker } from '../ColorPicker';

import * as S from './styles';

import { ICategoryModalProps } from './types';
export const ManageCategoryModal = ({ showModal, toggle, title, type }: ICategoryModalProps) => {
  // * [Theme] * //
  const theme = useTheme();

  // * [Contexts] * //
  const {
    category,
    //
    loadingCreateCategory,
    //
    setCategoryActionType,
    //
    handleCreateCategory,
    handleUpdateCategory,
  } = useCategories();

  // * [States] * //
  const [colorSelected, setColorSelected] = useState<string>(theme.gray200);
  const [iconSelected, setIconSelected] = useState<string>('');
  const [categoryName, setCategoryName] = useState<string>('');

  // * [Variables] * //
  const BUTTON_TEXT = category ? 'Editar Categoria' : 'Criar Categoria';
  const MODAL_TITLE = category ? `Editar Categoria ${category?.name}` : title;
  const CATEGORY_DATA = { name: categoryName, color: colorSelected, icon: iconSelected, type };

  // * [Functions] * //
  const toggleModal = useCallback(() => {
    setColorSelected(theme.gray200);
    setIconSelected('');
    setCategoryName('');
    setCategoryActionType(TransactionTypeEnum.Expense);
    toggle();
  }, []);

  const handleCategoryModalAction = useCallback(() => {
    if (category) {
      handleUpdateCategory(category.id, CATEGORY_DATA);
    } else {
      handleCreateCategory(CATEGORY_DATA);
    }
  }, [category, CATEGORY_DATA]);

  // * [Effects] * //
  useEffect(() => {
    if (category) {
      setColorSelected(category?.color);
      setIconSelected(category?.icon);
      setCategoryName(category?.name);
    }
  }, [category]);

  return (
    <Modal isOpen={showModal} toggle={toggleModal} centered>
      <S.CategoryModalHeader toggle={toggleModal}>{MODAL_TITLE}</S.CategoryModalHeader>

      <ModalBody>
        <S.CategoryModalInfo>
          <S.CategoryModalIcon bgColor={colorSelected}>
            <S.CategoryListItemIcon className={iconSelected} color="#fff" fontSize="24px" />
          </S.CategoryModalIcon>
          <S.CategoryModalInput
            placeholder="Nome da Categoria"
            onChange={(e) => setCategoryName(e.target.value)}
            value={categoryName}
          />
        </S.CategoryModalInfo>

        <S.CategoryListContainer>
          <S.CategoryListName>Escolha uma cor</S.CategoryListName>

          <CustomColorPicker color={colorSelected} setColor={setColorSelected} />

          <S.CategoryList>
            {distinctPastelColors.map((color, index) => (
              <S.CategoryListItem
                key={index}
                type="colors"
                color={color}
                active={colorSelected === color}
                onClick={() => setColorSelected(color)}
              />
            ))}
          </S.CategoryList>
        </S.CategoryListContainer>

        <S.CategoryListContainer>
          <S.CategoryListName>Escolha um ícone</S.CategoryListName>

          <S.CategoryList height="150px">
            {mdiIconsWebfont.map((icon, index) => (
              <S.CategoryListItem
                key={index}
                type="icons"
                color={theme.gray300}
                active={iconSelected === icon}
                onClick={() => setIconSelected(icon)}
              >
                <S.CategoryListItemIcon
                  color={iconSelected === icon ? theme.gray100 : ''}
                  className={icon}
                />
              </S.CategoryListItem>
            ))}
          </S.CategoryList>
        </S.CategoryListContainer>
      </ModalBody>

      <S.CategoryModalFooter>
        <Button
          color="primary"
          onClick={handleCategoryModalAction}
          disabled={loadingCreateCategory}
          className="d-flex justify-content-center align-items-center"
        >
          {loadingCreateCategory ? <Spinner size="sm" color="light" /> : BUTTON_TEXT}
        </Button>
      </S.CategoryModalFooter>
    </Modal>
  );
};

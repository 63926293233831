import { useState } from 'react';

interface UseDataFetchResult<T> {
  data: T;
  loading: boolean;
  error: Error | null;
  setData: (data: T) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: Error) => void;
}

/**
 *
 * useDataFetch
 *
 * @category Hooks - useDataFetch
 * @version 0.0.1
 *
 * @template T - The data type
 * @param { T | null} initialData - The initial data
 *
 * @return {UseDataFetchResult<T>} - The data fetch result
 */

const useDataFetch = <T>(initialData: T): UseDataFetchResult<T> => {
  const [data, setData] = useState<T>(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  return {
    data,
    loading,
    error,
    setData,
    setLoading,
    setError,
  };
};

export default useDataFetch;

import styled from 'styled-components';
export const PickerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-left: 8px;

  padding: 12px;
`;

export const StyledPicker = styled.div`
  position: absolute;
  top: 0;
  left: 15%;

  .chrome-picker {
    box-shadow: none !important;
    border: 1px solid #ccc;
    border-radius: 8px !important;
    padding: 0 !important;
  }

  .chrome-picker > div:nth-child(1) {
    border-radius: 8px 8px 0 0 !important;
  }

  .chrome-picker > div:nth-child(3) {
    display: none !important;
  }

  .chrome-picker > div:nth-child(3) {
    padding: 8px !important;
  }
`;

export const ColorPreviewWrapper = styled.div`
  width: 100%;
`;

export const ColorPreview = styled.div<{ color: string }>`
  width: 40px;
  height: 32px;

  background-color: ${(props) => props.color};
  border: 2px solid ${({ theme }) => theme.gray500};
  border-radius: 8px;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(0.7);
  }
`;

export const ColorPreviewBox = styled.div<{ color: string }>`
  width: 92%;
  height: 32px;

  margin-left: 12px;

  border-radius: 8px;
  background-color: ${(props) => props.color};
  border: 2px solid ${({ theme }) => theme.borderColor};
`;

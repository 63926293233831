import React from 'react';

import * as S from './styles';

import { IActionButtonProps } from './types';

export const ActionButton = ({
  onClick,
  iconClass,
  text,
  textColor,
  spinnerColor = 'light',
  size,
  isLoading,
}: IActionButtonProps) =>
  !isLoading ? (
    <S.ActionButton onClick={onClick} color={textColor} size={size}>
      <S.ActionButtonIcon className={iconClass} />
      {text}
    </S.ActionButton>
  ) : (
    <S.ActionButtonSpinner size="sm" color={spinnerColor} />
  );

import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { IActionModal, IPendingWithdrawalsApprove } from '@modules/PendingWithdrawals/types';
import { getAll } from '@modules/Client/submodules/BankDatas/services';
import Spinners from '@common/Spinner';
import { NumericFormat } from 'react-number-format';
import { useFormik } from 'formik';

const ActionModal = ({
  show,
  clientID,
  contractID,
  actionDescription,
  actionButtonText,
  actionButtonColor = 'danger',
  loading = false,
  onActionClick,
  onCloseClick,
}: IActionModal) => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      receiptFile: '',
      receiptName: '',
      observation: '',
      paymentType: '',
      bonus: '',
      contractID,
    },
    validationSchema: Yup.object({
      receiptFile: Yup.string(),
      receiptName: Yup.string(),
      observation: Yup.string(),
      paymentType: Yup.string(),
      bonus: Yup.string(),
    }),
    onSubmit: async (data: IPendingWithdrawalsApprove) => {
      onActionClick(data, validation.resetForm);
    },
  });

  const [enableBonus, setEnableBonus] = useState(false);
  const [invalidPayment, setInvalidPayment] = useState(false);

  const { data } = useQuery({
    queryKey: ['clientBankDatas', { clientID, page: 1, size: 10 }],
    queryFn: () => getAll(clientID, 1, 10),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const bankData = data.items.length > 0 ? data.items[0] : undefined;

  const handleChangePaymentType = (e: any) =>
    validation.setFieldValue('paymentType', e.target.value);

  const handleClick = () => {
    if (!validation.values.paymentType) {
      setInvalidPayment(true);
      return;
    }

    setInvalidPayment(false);
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      return;
    }

    const fileName = file.name;
    const reader = new FileReader();

    reader.onload = () => {
      let base64String = reader.result as string;
      base64String = base64String.split(',')[1];

      validation.setFieldValue('receiptName', fileName);
      validation.setFieldValue('receiptFile', base64String);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Modal isOpen={show} toggle={onCloseClick} size="lg" centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          {loading ? (
            <Row>
              <Col className="d-flex flex-column justify-content-center align-items-center">
                <h3 className="mb-4">Realizando upload...</h3>
                <Spinners />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col>
                  <p className="text-muted font-size-16 mb-4">{actionDescription}</p>
                </Col>
              </Row>

              <Row>
                {bankData && (
                  <Col>
                    <div style={{ textAlign: 'left' }}>
                      <p className="font-size-14">Dados bancários</p>
                      <ListGroup className="mb-4" style={{ textAlign: 'left' }}>
                        <ListGroupItem>
                          <strong>Tipo da conta:</strong>{' '}
                          {bankData.type === 'CC' ? 'Conta corrente' : 'Conta poupança'}
                        </ListGroupItem>
                        <ListGroupItem>
                          <strong>Agência:</strong> {bankData.agency}
                        </ListGroupItem>
                        <ListGroupItem>
                          <strong>Número da conta:</strong> {bankData.account}
                        </ListGroupItem>
                        <ListGroupItem>
                          <strong>Número do banco:</strong> {bankData.bank_number}
                        </ListGroupItem>
                        <ListGroupItem>
                          <strong>Instituição:</strong> {bankData.institution}
                        </ListGroupItem>
                        <ListGroupItem>
                          <strong>PIX:</strong> {bankData.pix}
                        </ListGroupItem>
                      </ListGroup>
                    </div>
                  </Col>
                )}

                <Col className="hstack gap-2 justify-content-center mb-0">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <FormGroup>
                        <Col md={12}>
                          <Label className="col-form-label d-flex">Tipo de pagamento</Label>
                          <Input
                            id="paymentType"
                            name="paymentType"
                            className="form-control"
                            type="select"
                            onChange={handleChangePaymentType}
                          >
                            <option value="">Selecione um tipo de pagamento</option>
                            <option value="DINHEIRO">DINHEIRO</option>
                            <option value="PIX">PIX</option>
                            <option value="TED">TED</option>
                            <option value="BOLETO">BOLETO</option>
                            <option value="CHEQUE">CHEQUE</option>
                            <option value="A_NEGOCIAR">A NEGOCIAR</option>
                          </Input>
                          {invalidPayment && (
                            <FormFeedback type="invalid" className="d-block text-start">
                              O tipo de pagamento é obrigatório!
                            </FormFeedback>
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-2">
                        <Col>
                          <Label className="col-form-label d-flex">Comprovante de pagamento</Label>
                          {!validation.values.receiptFile ? (
                            <Input
                              id="receiptFile"
                              name="receiptFile"
                              className="form-control"
                              type="file"
                              onChange={handleFile}
                              value={validation.values.receiptFile || ''}
                            />
                          ) : (
                            <div className="d-flex">
                              <Input value={validation.values.receiptFile} disabled />
                              <Button
                                color="danger"
                                className="ms-2"
                                id="removeFile"
                                onClick={() => validation.setFieldValue('receiptFile', '')}
                              >
                                <i className="mdi mdi-close me-0"></i>
                                <UncontrolledTooltip placement="top" target="removeFile">
                                  Remover arquivo
                                </UncontrolledTooltip>
                              </Button>
                            </div>
                          )}
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-2">
                        <Col>
                          <Label className="col-form-label d-flex">Observação</Label>
                          <Input
                            id="observation"
                            name="observation"
                            className="form-control"
                            type="textarea"
                            onChange={(data: React.ChangeEvent<HTMLInputElement>) =>
                              validation.setFieldValue('observation', data.target.value)
                            }
                            value={validation.values.observation || ''}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup className="mb-2">
                        <Col className="d-flex">
                          <Input
                            type="checkbox"
                            id="primary_contact"
                            name="primary_contact"
                            onChange={() => setEnableBonus(!enableBonus)}
                          />
                          <Label className="ps-2" check htmlFor="primary_contact">
                            Adicionar bonus
                          </Label>
                        </Col>
                      </FormGroup>

                      {enableBonus && (
                        <FormGroup className="mb-2">
                          <Col>
                            <Label className="col-form-label d-flex">Valor do bonus</Label>
                            <InputGroup>
                              <NumericFormat
                                id="bonus"
                                name="bonus"
                                className="form-control"
                                decimalSeparator=","
                                thousandSeparator="."
                                placeholder="Digite um valor"
                                prefix="R$ "
                                value={validation.values.bonus}
                                decimalScale={2}
                                onValueChange={(data: any) =>
                                  validation.setFieldValue('bonus', data.value)
                                }
                              />
                            </InputGroup>
                          </Col>
                        </FormGroup>
                      )}

                      <Col md={12} className="d-flex justify-content-end">
                        <button
                          type="submit"
                          disabled={!validation.values.paymentType}
                          className={`btn btn-${actionButtonColor} me-2`}
                          onClick={handleClick}
                        >
                          {actionButtonText}
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={onCloseClick}>
                          Cancelar
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ActionModal;

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

interface props {
  show: boolean;
  icon: string;
  iconColor?: string;
  actionDescription: string;
  actionButtonText: string;
  isLoading?: boolean;
  loadingText?: string;
  actionButtonColor?: string;
  onActionClick: () => void;
  onCloseClick: () => void;
}

const ActionModal = ({
  show,
  icon,
  iconColor = 'primary',
  actionDescription,
  actionButtonText,
  actionButtonColor = 'danger',
  isLoading = false,
  loadingText = 'Carregando...',
  onActionClick,
  onCloseClick,
}: props) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>

          {isLoading ? (
            <p className="text-muted font-size-16">{loadingText}</p>
          ) : (
            <>
              <div className="avatar-sm mb-4 mx-auto">
                <div
                  className={`avatar-title bg-${iconColor} text-${iconColor} bg-opacity-10 font-size-20 rounded-3`}
                >
                  <i className={icon}></i>
                </div>
              </div>
              <p className="text-muted font-size-16 mb-4">{actionDescription}</p>

              <div className="hstack gap-2 justify-content-center mb-0">
                <button
                  type="button"
                  className={`btn btn-${actionButtonColor}`}
                  onClick={onActionClick}
                >
                  {actionButtonText}
                </button>
                <button type="button" className="btn btn-secondary" onClick={onCloseClick}>
                  Cancelar
                </button>
              </div>
            </>
          )}
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ActionModal;

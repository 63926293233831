import { Input, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';

// * [Modal Header] * //
export const CategoryModalHeader = styled(ModalHeader)`
  border-bottom: none;
`;
// * [Modal Body] * //
export const CategoryModalInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
  padding-bottom: 24px;

  border-bottom: 2px solid ${({ theme }) => theme.borderColor};
`;
export const CategoryModalIcon = styled.span<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 48px;
  height: 48px;

  padding: 8px;

  border-radius: 100%;
  background: ${({ bgColor, theme }) => bgColor || theme.gray200};
  border: 2px solid ${({ theme }) => theme.gray500};
`;
export const CategoryModalInput = styled(Input)``;
export const CategoryListContainer = styled.div`
  margin-top: 24px;
  padding-bottom: 24px;

  border-bottom: 2px solid ${({ theme }) => theme.borderColor};

  &:last-child {
    border-bottom: none;
  }
`;
export const CategoryListName = styled.p`
  color: ${({ theme }) => theme.gray500};
`;
export const CategoryList = styled.div<{ height?: string }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;

  height: ${({ height }) => height || 'auto'};

  overflow-y: scroll;
`;

export const CategoryListItem = styled.button<{ color: string; active?: boolean; type?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  margin-right: 8px;

  border: none;
  border-radius: 100%;
  background: ${({ active, theme, type, color }) => {
    if (active) {
      if (type === 'colors') {
        return color;
      }
      if (type === 'icons') {
        return theme.gray500;
      }
    } else {
      if (type === 'colors') {
        return color;
      }
      if (type === 'icons') {
        return theme.gray300;
      }
    }
    return theme.gray300;
  }};

  border: ${({ active, theme, type }) => {
    if (active) {
      if (type === 'colors') {
        return `3px solid ${theme.gray800}`;
      }
    }
    return 'none'; // Sem borda quando inativo
  }};

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const CategoryListItemIcon = styled.i<{
  color?: string;
  fontSize?: string;
  active?: boolean;
}>`
  color: ${({ color, theme, active }) => (active ? theme.gray600 : color || theme.gray500)};
  font-size: ${({ fontSize }) => fontSize || '1.1rem'};

  text-shadow: ${({ theme }) => theme.boxShadow};
`;
// * [Modal Footer] * //
export const CategoryModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: center;

  padding-bottom: 32px;

  border-top: none;
`;

import { Input } from 'reactstrap';
import styled from 'styled-components';

export const SubCategoryItem = styled.div<{
  color?: string;
  isLastCategoryItem?: boolean;
  isLasSubCategoryItem?: boolean;
}>`
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  padding: 16px 0 16px 48px;

  border-bottom: ${({ isLastCategoryItem, isLasSubCategoryItem, theme }) =>
    isLastCategoryItem && isLasSubCategoryItem ? 'none' : `2px solid ${theme.borderColor}`};

  &::before {
    position: absolute;
    left: 28px;
    content: '';
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background: ${({ color }) => color || 'rgba(250, 250, 250, 0.1)'};
    border-radius: 100%;
  }
`;

export const SubCategoryBadgeWrapper = styled.span<{ color?: string }>`
  display: flex;
  align-items: center;

  width: 100%;

  cursor: pointer;

  &:hover > div {
    opacity: 1;
    visibility: visible;
  }

  &:hover > span {
    filter: brightness(0.7);
  }
`;

export const SubCategoryInput = styled(Input)`
  width: 20%;
`;

export const SubCategoryBadgeText = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.gray500};

  transition: all 0.3s ease-in-out;
`;

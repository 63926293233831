import React, { useState } from 'react';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { Badge, Button } from 'reactstrap';
import { moneyTransform } from '@helpers/transforms/money';
import moment from 'moment';

const TRow = ({ row, dragActiveColumn, dragOver }: any) => {
  const [isExpansible, setIsExpansible] = useState(false);

  const transactions = row.original.transactions || [];

  return (
    <>
      <tr key={row.id}>
        {row.getVisibleCells().map((cell, index) => (
          <td
            className={classNames(`${dragActiveColumn} ${index}`, {
              'table-draggable-active_tbody': dragActiveColumn === index,
              'table-draggle-hover': dragOver === index,
              'd-flex': index === row.getVisibleCells().length - 1,
            })}
            colSpan={cell.colSpan}
            key={cell.id}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}

            {index === row.getVisibleCells().length - 1 && transactions.length > 0 && (
              <Button
                size="sm"
                color="success"
                className="ms-3"
                onClick={() => setIsExpansible(!isExpansible)}
              >
                <i className="mdi mdi-plus me-0"></i>
              </Button>
            )}
          </td>
        ))}
      </tr>
      {isExpansible && (
        <>
          <tr className="table-expansible-row">
            <td colSpan={row.getVisibleCells().length}>
              <h2 className="font-size-16 text-white mb-0 p-1">Bonificações</h2>
            </td>
          </tr>
          {transactions.map((transaction: any) => (
            <tr key={transaction.id} className="table-expansible-row">
              <td colSpan={row.getVisibleCells().length} key={transaction.id}>
                <div className="d-flex align-items-center p-1 pt-2 pb-2">
                  <div>
                    <Badge pill color="primary" className="font-size-14">
                      {transaction.type}
                    </Badge>
                  </div>
                  <div className="ms-2">
                    <Badge pill color="success" className="font-size-14">
                      {transaction.payment_type}
                    </Badge>
                  </div>
                  <h5 className="font-size-14 ms-2 mb-0 text-success">
                    {moneyTransform(transaction.value)}
                  </h5>
                  <h5 className="font-size-14 ms-2 mb-0 text-white">
                    {moment(transaction.effective_date).utcOffset(-6).format('DD/MM/YYYY')}
                  </h5>
                </div>
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  );
};

export default TRow;

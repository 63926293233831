import React from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { TransactionTypeEnum } from '@modules/Finance/ultis/categories/enums';

import { ICategoryTabsProps } from './types';

export const CategoryTabs = ({ activeTab, toggleTab }: ICategoryTabsProps) => (
  <Nav tabs className="nav-tabs-custom justify-content-center gap-5 pt-2" role="tablist">
    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === TransactionTypeEnum.Expense,
        })}
        onClick={() => toggleTab(TransactionTypeEnum.Expense)}
      >
        Despesas
      </NavLink>
    </NavItem>

    <NavItem>
      <NavLink
        to="#"
        className={classnames({
          active: activeTab === TransactionTypeEnum.Income,
        })}
        onClick={() => toggleTab(TransactionTypeEnum.Income)}
      >
        Receitas
      </NavLink>
    </NavItem>
  </Nav>
);

import { Button } from 'reactstrap';
import styled from 'styled-components';

export const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`;
export const CategoryTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
`;
export const CategoryCreateButton = styled(Button)``;

export const CategoryIcon = styled.span``;

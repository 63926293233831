import React, { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import withRouter from '@component/common/withRouter';
import LinkPartnerForm from '@modules/Contract/submodules/Partner/components/LinkPartnerForm';
import { IContractCreate } from '@modules/Contract/types';
import { getByID } from '@modules/Contract/services';
import Spinners from '@component/common/Spinner';
import { Detail } from '@modules/Contract/submodules/Partner/components/Detail';
import moment from 'moment';
import Tabs from '@modules/Partners/components/Tabs';
import ActionModal from '@component/common/ActionModal';
import { unlinkPartner } from '../services';
import { toast } from 'react-toastify';
import { REDIRECT_TIMEOUT } from '@helpers/constants';

const DetailLinkPartner = ({ router }: IWithRouter & IContractCreate) => {
  const [modal, setModal] = useState(false);
  const { contractID, partnerID } = useParams();

  const { mutate, isPending } = useMutation({
    mutationFn: () => unlinkPartner(contractID || ''),
    onSuccess: () => {
      setModal(false);

      toast.success('Acessor desvinculado com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setTimeout(() => {
        router.navigate('/contracts');
      }, REDIRECT_TIMEOUT);
    },
    onError: () => {
      toast.error('Ocorreu um erro ao desvincular o acesso!', {
        theme: 'colored',
        autoClose: 1500,
      });
    },
  });

  const { data, isFetching, error } = useQuery({
    queryKey: ['contract', contractID],
    queryFn: () => getByID(contractID || ''),
    retry: 1,
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      partner: {
        value: data?.partner_id,
        label: data?.partner?.name,
      },
      expirationDate:
        moment(data?.expiration_partner_at).format('YYYY-MM-DD') ||
        moment().add(5, 'months').format('YYYY-MM-DD'),
      linkedAt: moment(data?.linked_partner_at).format('YYYY-MM-DD') || '',
      recurringWithdrawal: data?.recurring_withdrawal ? 'true' : 'false',
      partnerPercentage: Number(data?.partner_percentage || 0),
    },
    validationSchema: Yup.object({
      partner: Yup.object().required(),
      expirationDate: Yup.string().required(),
    }),
    onSubmit: () => {},
  });

  const handleUnlink = () => {
    mutate();
  };

  const toogleTab = (activeTab: string) => {
    router.navigate(`/partners/${partnerID}/${activeTab}`);
  };

  return (
    <Page
      title="Vincular acessor ao contrato"
      breadcrumb={{
        title: 'Contratos',
        titleHref: `/contracts`,
        breadcrumbItem: 'Acessor vinculado ao contato',
      }}
    >
      <Row>
        <Col lg={12}>
          {isFetching ? (
            <Spinners />
          ) : (
            <>
              <Detail
                identificationNumber={data?.identification_number || ''}
                profitabilityGroupDescription={data?.profitability_group?.description || ''}
                profitabilityGroupPercentage={data?.profitability_group?.percentage || ''}
                contractID={contractID || ''}
              />
              <Card>
                <Tabs activeTab="contracts" toggleTab={toogleTab} />
                <CardBody>
                  <CardTitle className="mb-4">Acessor vinculado ao contrato</CardTitle>
                  <div className="p-2 mt-3">
                    <LinkPartnerForm
                      loading={isFetching}
                      validation={validation}
                      registrationError={error?.message}
                      showAlert={false}
                      type="edit"
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button color="danger" onClick={() => setModal(!modal)}>
                      Desvincular acessor
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
      <ActionModal
        show={modal}
        onCloseClick={() => setModal(false)}
        onActionClick={handleUnlink}
        actionButtonText="Desvincular"
        actionDescription="Tem certeza que deseja desvincular o acessor ao contrato?"
        actionButtonColor="success"
        iconColor="warning"
        icon="mdi mdi-alert-outline"
        isLoading={isPending}
        loadingText="Desvinculando acessor..."
      />
    </Page>
  );
};

export default withRouter(DetailLinkPartner);

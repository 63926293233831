import styled from 'styled-components';

export const AccordionRootContainer = styled.div<{ hasSubCategories?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;

  &:last-child > div {
    border-bottom: ${({ hasSubCategories }) => !hasSubCategories && 'none'};
  }
`;

import React from 'react';

import { useCategories } from '@modules/Finance/contexts/CategoriesContext';

import { SubCategoryActions } from '../SubCategoryActions';

import * as S from './styles';

import { ISubCategoryListProps } from './types';

export const SubCategoryList = ({
  category,
  subCategories,
  isLastCategoryItem,
}: ISubCategoryListProps) => {
  // * [Context] * //
  const { selectedCategoryId } = useCategories();

  return (
    <>
      {selectedCategoryId === category?.id && (
        <S.SubCategoryItem color={category?.color}>
          <SubCategoryActions category={category} isAddingSubCategory={true} />
        </S.SubCategoryItem>
      )}

      {subCategories?.map((subCategory) => (
        <S.SubCategoryItem
          key={subCategory?.id}
          color={category?.color}
          isLastCategoryItem={isLastCategoryItem}
          isLasSubCategoryItem={subCategories?.indexOf(subCategory) === subCategories?.length - 1}
        >
          <SubCategoryActions category={category} subCategory={subCategory} />
        </S.SubCategoryItem>
      ))}
    </>
  );
};

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import withRouter from '@common/withRouter';
import ActionModal from '@modules/PendingWithdrawals/components/ActionModal';
import TableContainer from '@common/TableContainer';
import { IWithRouter } from '@apptypes/WithRouter';
import { Page } from '@component/common/Page';
import { buildColumns } from '../components/Table/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { approveWithdrawalsByID, exportData, getAll } from '../services';
import { toast } from 'react-toastify';
import { ColumnFiltersState } from '@tanstack/react-table';
import { IPendingWithdrawalsApprove } from '../types';

const PendingWithdrawals = ({ page, size, setSearchParams }: IWithRouter) => {
  const [actionModalCallback, setActionModalCallback] = useState<any>(undefined);
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const { user }: any = useSelector((state: any) => state.Profile);
  const { data, error, isError, isFetching, refetch } = useQuery({
    queryKey: ['pendingWithdrawals', { page, size, filters }],
    queryFn: () => getAll(page, size, 'false', filters),
    initialData: { items: [], totalItems: 0 },
    retry: 1,
  });

  const approveMutation = useMutation({
    mutationFn: (payload: {
      withdrawalID: string;
      contractTransactionID: string;
      paymentType: string;
      receiptFile: string;
      receiptName: string;
      observation: string;
      bonus?: string;
      contractID: string;
    }) => approveWithdrawalsByID(payload),
    onSuccess: () => {
      if (actionModalCallback) {
        actionModalCallback();
      }

      toast.success('Retirada aprovada com sucesso!', {
        theme: 'colored',
        autoClose: 1500,
      });

      setSearchParams({ page: '1' });
      setActionModal(false);
      refetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: 2000,
        theme: 'colored',
      });
    },
  });

  if (isError) {
    toast.error(error.message, {
      autoClose: 5000,
      theme: 'colored',
    });
  }

  const [actionModal, setActionModal] = useState<boolean>(false);
  const [withdrawalSelectedID, setWithdrawalSelectedID] = useState<any>(null);
  const [contractID, setContractID] = useState<any>(null);
  const [contractTransactionID, setContractTransactionID] = useState<any>(null);
  const [clientID, setClientID] = useState<any>(null);

  const handleWithdrawalSelectedModal = (
    withdrawalID: string,
    contractsTransactionsID: string,
    clientID: string,
    contractID: string,
  ) => {
    setContractID(contractID);
    setWithdrawalSelectedID(withdrawalID);
    setContractTransactionID(contractsTransactionsID);
    setClientID(clientID);
    setActionModal(true);
  };

  const handleAcceptWithdrawal = async (
    {
      receiptFile,
      receiptName,
      observation,
      paymentType,
      bonus,
      contractID,
    }: IPendingWithdrawalsApprove,
    callback: any,
  ) => {
    if (callback) {
      setActionModalCallback(callback);
    }

    if (withdrawalSelectedID && contractTransactionID) {
      approveMutation.mutate({
        withdrawalID: withdrawalSelectedID,
        contractTransactionID,
        paymentType,
        receiptFile,
        receiptName,
        observation,
        bonus,
        contractID,
      });
    }
  };

  const handleExportData = async () => {
    filters.push({
      id: 'confirmed',
      value: false,
    });

    const fileUrl = await exportData(page, size, filters);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = `pending-withdrawals-export.csv`;
    link.click();
  };

  return (
    <Page
      title="Retiradas pendentes"
      breadcrumb={{
        title: 'Retiradas pendentes',
        titleHref: '/pending-withdrawals',
        breadcrumbItem: 'Listar retiradas pendentes',
      }}
    >
      <ActionModal
        clientID={clientID}
        contractID={contractID}
        show={actionModal}
        actionDescription="Antes de aprovar a retirada, selecione do tipo de pagamento!"
        actionButtonText="Aprovar"
        actionButtonColor="success"
        loading={approveMutation.isPending}
        onActionClick={handleAcceptWithdrawal}
        onCloseClick={() => setActionModal(false)}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                moduleName="pendingWithdrawals"
                columns={buildColumns(user.is_admin, handleWithdrawalSelectedModal)}
                customElement={
                  <Button type="button" color="primary" outline onClick={handleExportData}>
                    Exportar dados
                  </Button>
                }
                customPageSize={size}
                currentPage={page}
                data={data.items}
                loading={isFetching}
                totalItems={data.totalItems}
                handleFilters={setFilters}
                setSearchParams={setSearchParams}
                refetch={refetch}
                isPagination
                isShowingPageLength
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default withRouter(PendingWithdrawals);

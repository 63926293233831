export const distinctPastelColors = [
  '#D57BB0',
  '#84B289',
  '#ABDCE8',
  '#967EEB',
  '#DEBB77',
  '#AA9ABC',
  '#8D7C7A',
  '#E4A8E7',
  '#E7B9AF',
  '#75E4D4',
  '#7CB1DD',
  '#CC6F77',
  '#81E47B',
  '#E0E98D',
  '#ABDFA7',
  '#E0827D',
  '#7A7CB3',
  '#E476E7',
];

export const mdiIconsWebfont = [
  // Receitas
  'mdi mdi-cash',
  'mdi mdi-bank',
  'mdi mdi-wallet',
  'mdi mdi-handshake-outline',
  'mdi mdi-cash-multiple',
  'mdi mdi-cash-register',
  'mdi mdi-currency-usd',
  'mdi mdi-credit-card',
  'mdi mdi-briefcase-outline',
  'mdi mdi-domain',
  'mdi mdi-chart-line',
  'mdi mdi-currency-eur',
  'mdi mdi-currency-gbp',
  'mdi mdi-trending-up',
  'mdi mdi-arrow-up-bold-circle',
  'mdi mdi-currency-inr',
  'mdi mdi-gift',
  'mdi mdi-finance',
  'mdi mdi-home-modern',

  // Despesas
  'mdi mdi-cart',
  'mdi mdi-cart-outline',
  'mdi mdi-car',
  'mdi mdi-gas-station',
  'mdi mdi-home-outline',
  'mdi mdi-silverware-fork-knife',
  'mdi mdi-basket',
  'mdi mdi-basket-outline',
  'mdi mdi-hammer-screwdriver',
  'mdi mdi-phone-outline',
  'mdi mdi-lightbulb-outline',
  'mdi mdi-water-pump',
  'mdi mdi-wrench-outline',
  'mdi mdi-tools',
  'mdi mdi-school-outline',
  'mdi mdi-medical-bag',
  'mdi mdi-hospital-box-outline',
  'mdi mdi-pill',
  'mdi mdi-food-drumstick',
  'mdi mdi-food-apple-outline',

  // Outros Úteis
  'mdi mdi-calendar',
  'mdi mdi-calendar-check',
  'mdi mdi-credit-card-outline',
  'mdi mdi-cash-100',
  'mdi mdi-trophy-outline',
  'mdi mdi-airplane',
  'mdi mdi-bus',
  'mdi mdi-train',
  'mdi mdi-bike',
  'mdi mdi-beer-outline',
  'mdi mdi-coffee-outline',
  'mdi mdi-smoking-off',
  'mdi mdi-guitar-pick',
  'mdi mdi-music-note',
  'mdi mdi-video-vintage',
  'mdi mdi-chair-rolling',
  'mdi mdi-book-open-outline',
  'mdi mdi-heart-outline',
  'mdi mdi-baby-carriage',
  'mdi mdi-gift-outline',

  // Complementares
  'mdi mdi-credit-card-multiple-outline',
  'mdi mdi-currency-usd-off',
  'mdi mdi-account-cash-outline',
  'mdi mdi-chart-bar-stacked',
  'mdi mdi-monitor',
  'mdi mdi-sack-percent',
  'mdi mdi-store-outline',
  'mdi mdi-shopping-outline',
  'mdi mdi-tag-outline',
  'mdi mdi-tag-multiple',
];

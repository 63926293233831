import React, { useEffect, useState, useCallback } from 'react';
import { useTheme } from 'styled-components';

import { useCategories } from '@modules/Finance/contexts/CategoriesContext';
import { SubCategoryActionTypeEnum } from '@modules/Finance/ultis/categories/enums';

import { ActionButton } from '../ActionButton';

import * as S from './styles';

import { ISubCategoryActionsProps } from './types';

export const SubCategoryActions = ({
  category,
  subCategory,
  isAddingSubCategory,
}: ISubCategoryActionsProps) => {
  // * [Hooks] * //
  const theme = useTheme();

  // * [Contexts] * //
  const {
    selectedSubCategoryId,
    subCategoryActionType,
    //
    loadingCreateSubCategory,
    loadingUpdateSubCategory,
    //
    handleCreateSubCategory,
    handleUpdateSubCategory,
    handleDeleteSubCategory,
    //
    setSelectedCategoryId,
    setSelectedSubCategoryId,
    setSubCategoryActionType,
  } = useCategories();

  // * [States] * //
  const [subCategoryName, setSubCategoryName] = useState('');

  // * [Variables] * //
  const isEditing = subCategoryActionType === SubCategoryActionTypeEnum.Update;
  const isCreating = isAddingSubCategory && !isEditing;
  const isEditingOrCreating =
    isCreating || (isEditing && selectedSubCategoryId === subCategory?.id);

  // * [Functions] * //
  const resetState = useCallback(() => {
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setSubCategoryActionType(null);
    setSubCategoryName('');
  }, [setSelectedCategoryId, setSelectedSubCategoryId, setSubCategoryActionType]);

  const handleSave = useCallback(() => {
    if (isCreating) {
      handleCreateSubCategory(category?.id, subCategoryName);
    } else if (isEditing && selectedSubCategoryId) {
      handleUpdateSubCategory(selectedSubCategoryId, category?.id, subCategoryName);
    }
    resetState();
  }, [
    isCreating,
    isEditing,
    subCategoryName,
    selectedSubCategoryId,
    category?.id,
    handleCreateSubCategory,
    handleUpdateSubCategory,
    resetState,
  ]);

  const handleDelete = useCallback(() => {
    if (subCategory?.id) {
      handleDeleteSubCategory(subCategory?.id);
      resetState();
    }
  }, [selectedSubCategoryId, handleDeleteSubCategory, resetState]);

  const handleEditStart = useCallback(() => {
    setSelectedSubCategoryId(subCategory?.id ?? null);
    setSubCategoryActionType(SubCategoryActionTypeEnum.Update);
    setSelectedCategoryId(null);
  }, [subCategory?.id, setSelectedSubCategoryId, setSubCategoryActionType, setSelectedCategoryId]);

  // * [Effects] * //
  useEffect(() => {
    if (subCategory && selectedSubCategoryId === subCategory?.id) {
      setSubCategoryName(subCategory?.name || '');
    }
  }, [subCategory, selectedSubCategoryId]);

  return (
    <S.SubCategoryBadgeWrapper color={category?.color}>
      {isEditingOrCreating ? (
        <S.SubCategoryInput
          type="text"
          placeholder="Nome da subcategoria"
          value={subCategoryName}
          onChange={(e) => setSubCategoryName(e.target.value)}
        />
      ) : (
        <S.SubCategoryBadgeText>{subCategory?.name}</S.SubCategoryBadgeText>
      )}

      <S.SubCategoryActionsButtons noSubCategoryAction={!isEditingOrCreating}>
        {isEditingOrCreating ? (
          <>
            <ActionButton
              onClick={handleSave}
              iconClass="mdi mdi-check"
              text={isCreating ? 'Adicionar' : 'Salvar'}
              textColor={theme.success}
              isLoading={loadingCreateSubCategory || loadingUpdateSubCategory}
            />
            <ActionButton
              onClick={resetState}
              iconClass="mdi mdi-close"
              text="Cancelar"
              textColor={theme.danger}
            />
          </>
        ) : (
          <>
            <ActionButton
              onClick={handleEditStart}
              iconClass="mdi mdi-pencil"
              text="Editar"
              textColor={theme.info}
            />
            <ActionButton
              onClick={handleDelete}
              iconClass="mdi mdi-delete"
              text="Excluir"
              textColor={theme.danger}
            />
          </>
        )}
      </S.SubCategoryActionsButtons>
    </S.SubCategoryBadgeWrapper>
  );
};

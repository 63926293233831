import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, InputGroup, Label } from 'reactstrap';
import moment from 'moment';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

import Spinners from '@common/Spinner';
import { getWithExpiry, setWithExpiry } from '@helpers/localStorage';
import ExtractTable from '@modules/Contract/submodules/Extract/components/ExtractTable';
import { getAllStatements } from '@modules/Contract/services';
import { IContractCreate } from '@modules/Contract/types';
import { IClientDocumentsCreate } from '@modules/Client/types';

const Extracts = ({
  contractID,
  contract,
  clientDocument,
}: {
  contractID: string;
  contract?: IContractCreate;
  clientDocument: IClientDocumentsCreate[];
}) => {
  const localStorageDates = getWithExpiry(contractID);
  const filterDates = localStorageDates ? JSON.parse(localStorageDates) : null;
  const [{ initialDate, finalDate }, setDates] = useState<Record<string, Date | undefined>>({
    initialDate: filterDates?.initialDate
      ? moment(filterDates?.initialDate).toDate()
      : moment().subtract(1, 'months').toDate(),
    finalDate: moment(filterDates?.finalDate).toDate(),
  });

  const {
    data: extract,
    isFetching: loading,
    refetch,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['extracts', contractID],
    queryFn: () =>
      getAllStatements({
        contractID,
        initialDate: moment(initialDate).format('YYYY-MM-DD'),
        finalDate: moment(finalDate).format('YYYY-MM-DD'),
      }),
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (initialDate && finalDate) {
      const thirtyMinutes = 1000 * 60 * 30;
      setWithExpiry(
        contractID,
        JSON.stringify({
          initialDate: moment(initialDate).format('YYYY-MM-DD'),
          finalDate: moment(finalDate).format('YYYY-MM-DD'),
        }),
        thirtyMinutes,
      );

      refetch();
      return;
    }

    toast.error('Por favor, selecione uma data inicial e uma data final.', {
      autoClose: 5000,
      theme: 'colored',
    });
  };

  const handlePrint = () => {
    const title = document.title;

    document.title = `${contract?.identification_number}-${contract?.client.name.toLowerCase()}`;
    window.print();

    document.title = title;
  };

  return (
    <div className="d-flex clients-content extracts">
      <Helmet>
        <title>Extratos do cliente | SGI</title>
      </Helmet>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="mb-4" row>
          <Col lg={3} md={3}>
            <InputGroup style={{ width: '340px', maxWidth: '340px' }}>
              <Label className="col-form-label me-2">Selecione uma data:</Label>
              <Flatpickr
                name="dates"
                className="form-control d-block"
                placeholder="Selecione uma data"
                onChange={(e) => {
                  setDates({
                    initialDate: moment(e[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    finalDate: moment(e[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
                  });
                }}
                options={{
                  mode: 'range',
                  altInput: true,
                  altFormat: 'd/m/Y',
                  dateFormat: 'Y-m-d',
                  defaultDate: [
                    moment(initialDate).format('YYYY-MM-DD'),
                    moment(finalDate).format('YYYY-MM-DD'),
                  ],
                  locale: Portuguese,
                }}
              />
            </InputGroup>
          </Col>
          <Col lg={7} md={7}>
            <Button type="submit" color="primary" className="btn">
              Buscar por período
            </Button>
          </Col>

          <Col md={2} className="d-flex justify-content-end">
            {extract?.items?.length && (
              <Button type="submit" color="light" className="btn" onClick={handlePrint}>
                <i className="mdi mdi-file-document-outline font-size-14" id="edittooltip" />
                Imprimir
              </Button>
            )}
          </Col>
        </FormGroup>
      </Form>

      {loading ? (
        <Spinners />
      ) : (
        <>
          {extract && document && contract && (
            <ExtractTable
              extract={extract}
              clientDocument={clientDocument}
              contract={contract}
              initialDate={initialDate || new Date()}
              finalDate={finalDate || new Date()}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Extracts;
